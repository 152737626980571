var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.singleOrder.contraEntryOrderId)?_c('div',{staticClass:"pa-2 mr-4 v-btn v-btn--flat v-btn--text theme--light v-size--default white--text"},[_vm._v(" Kreditordre ")]):_vm._e(),(
      _vm.checkOrderActionButtonAvailable(_vm.singleOrder.status.id, _vm.CourseOrderButtonValues.ValidateAvailable) &&
      !_vm.isEditing &&
      (_vm.isEditable || _vm.isEditableContra)
    )?_c('v-btn',{staticClass:"py-2 pr-1 mr-3 pl-4",attrs:{"color":"white","outlined":""},on:{"click":function($event){return _vm.$emit('setEdit')}}},[_vm._v("Rediger ordre"),_c('v-icon',{staticClass:"pl-4"},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.checkOrderActionButtonAvailable(_vm.singleOrder.status.id, _vm.CourseOrderButtonValues.CancelAvailable))?_c('v-btn',{staticClass:"py-2 pr-1 pl-4",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.$emit('cancelOrder')}}},[_vm._v("Avbryt ordre"),_c('v-icon',{staticClass:"pl-4"},[_vm._v("mdi-file-cancel-outline")])],1):_vm._e(),(
      _vm.checkOrderActionButtonAvailable(_vm.singleOrder.status.id, _vm.CourseOrderButtonValues.ValidateAvailable) && !_vm.isEditing
    )?_c('v-btn',{staticClass:"py-2 pr-1 pl-4",attrs:{"color":"white","outlined":""},on:{"click":function($event){return _vm.$emit('handleOrder')}}},[_vm._v("Godkjenn ordre"),_c('v-icon',{staticClass:"pl-4"},[_vm._v("mdi-file-sign")])],1):_vm._e(),(_vm.checkOrderActionButtonAvailable(_vm.singleOrder.status.id, _vm.CourseOrderButtonValues.QueueAvailable))?_c('v-btn',{staticClass:"py-2 pr-1 pl-4",attrs:{"color":"white","outlined":""},on:{"click":function($event){return _vm.$emit('handleOrder')}}},[_vm._v("Legg i kø"),_c('v-icon',{staticClass:"pl-4"},[_vm._v("mdi-file-send-outline")])],1):_vm._e(),(
      _vm.checkOrderActionButtonAvailable(_vm.singleOrder.status.id, _vm.CourseOrderButtonValues.ContraAvailable) &&
      !_vm.singleOrder.contraEntryOrderId
    )?_c('v-btn',{staticClass:"py-2 pr-1 pl-4",attrs:{"color":"white","outlined":""},on:{"click":function($event){return _vm.$emit('handleOrder')}}},[_vm._v("Opprett kreditordre"),_c('v-icon',{staticClass:"pl-4"},[_vm._v("mdi-file-restore-outline")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }