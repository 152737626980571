




























































import { ApiGetOrderDto } from "@/api/generated/Api";
import { CourseOrderButtonValues } from "@/shared/enums/courseOrderButtonValues.enum";
import { CourseOrderPaymentType } from "@/shared/enums/courseOrderPaymentType.enum";
import { checkOrderActionButtonAvailable } from "@/shared/helpers/courseOrderHelpers";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "SingleOrderHeaderAndSubmitActions",
  props: {
    singleOrder: {
      type: Object as PropType<ApiGetOrderDto>,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleOrder", "setEdit", "cancelOrder"],
  setup(props) {
    return {
      isEditable: computed(() => !props.singleOrder.contraEntryOrderId),
      isEditableContra: computed(
        () =>
          props.singleOrder.contraEntryOrderId &&
          props.singleOrder.paymentType !== CourseOrderPaymentType.severalDeductions
      ),
      CourseOrderButtonValues,
      checkOrderActionButtonAvailable,
    };
  },
});
